import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  CircularProgress,
  Button,
  Grid,
} from "@material-ui/core";
import EtherscanLink from "../atom/EtherscanLink";
import MarketplaceLink from "../atom/MarketplaceLink";
import { ChainId } from "@usedapp/core";
import { useNft } from "use-nft";
import { AppCardInfo } from "./AppCard";
import { useIPFSImageURL } from "../../hooks/useIPFSImageURL";

export interface WaitingProcessDialogProps {
  readonly chainId: ChainId | undefined;
  readonly cardInfo: AppCardInfo[];
  readonly purchaseTokenIndex: number | undefined;
  readonly setPurchaseTokenIndex: (index: number | undefined) => void;
  readonly transactionStatus: any;
}

const WaitingProcessDialog: React.FC<WaitingProcessDialogProps> = ({
  chainId,
  cardInfo,
  purchaseTokenIndex,
  setPurchaseTokenIndex,
  transactionStatus,
}) => {
  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState<string[]>([]);

  const tokenId = useMemo(() => {
    if (purchaseTokenIndex === undefined) {
      return "0";
    }
    return cardInfo[purchaseTokenIndex].id.toString();
  }, [cardInfo, purchaseTokenIndex]);

  const { nft } = useNft(process.env?.REACT_APP_NFT_ADDRESS ?? "", tokenId);

  const imageURL = useIPFSImageURL(nft);

  useEffect(() => {
    switch (transactionStatus.status) {
      case "Mining":
        setMessages([]);
        break;
      case "Success":
        setMessages([]);
        break;
      case "Fail":
      case "Exception":
        setMessages([
          "Failed to purchase",
          "(Possibly wallet balance is insufficient).",
          "Please reload an app and try again.",
        ]);
        break;
      default:
        setMessages([]);
    }
  }, [transactionStatus.status]);

  useEffect(() => {
    if (transactionStatus.status !== "None") {
      setOpen(true);
    }
  }, [transactionStatus.status]);

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle
        id="simple-dialog-title"
        style={{ textAlign: "center", color: "#F77BA0" }}
      >
        {transactionStatus.status === "Success"
          ? "You've successfully purchased!"
          : "Please Wait..."}
      </DialogTitle>
      <Box m={1} textAlign="center">
        {messages.map((message: string, index: number) => (
          <Typography key={index}>{message}</Typography>
        ))}
        {(transactionStatus.status === "None" ||
          transactionStatus.status === "Mining") && (
          <>
            <Typography>Please wait at the same screen.</Typography>
            <Box m={2}>
              <CircularProgress
                color="secondary"
                style={{ width: 100, height: 100 }}
              />
            </Box>
            <Box mt={1} mb={1}>
              <EtherscanLink
                txHash={transactionStatus?.transaction?.hash ?? ""}
              />
            </Box>
          </>
        )}
        {transactionStatus.status === "Success" && (
          <>
            <img
              src={imageURL}
              alt={nft?.name ?? ""}
              style={{ width: "100%", height: "100%" }}
            />
            <Grid container spacing={1} style={{ marginBottom: 8 }}>
              <Grid item xs={12} sm={6}>
                <EtherscanLink
                  txHash={transactionStatus?.transaction?.hash ?? ""}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MarketplaceLink
                  chainId={chainId?.toString() ?? "137"}
                  market={"opensea"}
                  address={process.env?.REACT_APP_NFT_ADDRESS ?? ""}
                  tokenId={tokenId}
                />
              </Grid>
            </Grid>
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setPurchaseTokenIndex(undefined);
                  setOpen(false);
                }}
                disableElevation
                fullWidth
              >
                OK
              </Button>
            </Box>
          </>
        )}
        {transactionStatus.status === "Fail" && (
          <>
            <Box style={{ margin: "8px 0 8px" }}>
              <EtherscanLink
                txHash={transactionStatus?.transaction?.hash ?? ""}
              />
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setPurchaseTokenIndex(undefined);
                  setOpen(false);
                }}
                disableElevation
                fullWidth
              >
                OK
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Dialog>
  );
};

export default WaitingProcessDialog;
