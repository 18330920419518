import { BigNumber, ethers } from "ethers";
import { useContractCall } from "@usedapp/core";
import { pipryctoABI } from "../constants";

export function usePricesOf(tokenAddress: string, tokenIdList: BigNumber[]) {
  const [prices] =
    useContractCall({
      abi: new ethers.utils.Interface(pipryctoABI),
      address: tokenAddress,
      method: "pricesOf",
      args: [tokenIdList],
    }) ?? [];
  return prices;
}
