import * as React from "react";
import { ChainId } from "@usedapp/core";
import { AppCardInfo } from "../molecules/AppCard";
import { AppFooter } from "../molecules/AppFooter";
import { AppHeader } from "../molecules/AppHeader";
import PurchaseDialog from "../molecules/PurcheseDialog";
import WaitingProcessDialog from "../molecules/WaitingProcessDialog";

import Arts from "../organisms/Arts";
import { BigNumber } from "ethers";
import ChainDialog from "../molecules/ChainDialog";

export interface PurchasePageTemplateProps {
  account?: string | null;
  buy: (...args: any[]) => void;
  buyState: any;
  cardInfo: AppCardInfo[];
  chainId?: ChainId;
  purchaseTokenIndex: number | undefined;
  setPurchaseTokenIndex: (index: number | undefined) => void;
  walletBalance: BigNumber | undefined;
}

function PurchasePageTemplate(props: PurchasePageTemplateProps) {
  return (
    <>
      <div>
        <AppHeader account={props.account} />
        <Arts
          cardInfo={props.cardInfo}
          setPurchaseTokenIndex={props.setPurchaseTokenIndex}
        />
        <ChainDialog chainId={props.chainId} />
        {props.buyState.status === "None" ? (
          <PurchaseDialog
            account={props.account}
            walletBalance={props.walletBalance}
            purchaseTokenIndex={props.purchaseTokenIndex}
            setPurchaseTokenIndex={props.setPurchaseTokenIndex}
            cardInfo={props.cardInfo}
            buy={props.buy}
          />
        ) : (
          <WaitingProcessDialog
            cardInfo={props.cardInfo}
            chainId={props.chainId}
            purchaseTokenIndex={props.purchaseTokenIndex}
            setPurchaseTokenIndex={props.setPurchaseTokenIndex}
            transactionStatus={props.buyState}
          />
        )}
        <AppFooter />
      </div>
    </>
  );
}

export default PurchasePageTemplate;
