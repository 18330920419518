import React from "react";
import {
  CircularProgress,
  Container,
  Grid,
  makeStyles,
} from "@material-ui/core";
import AppCard, { AppCardInfo } from "../molecules/AppCard";

const useStyles = makeStyles((theme) => ({
  card: {
    paddingTop: 84,
  },
}));

export interface ArtsProps {
  cardInfo: AppCardInfo[];
  setPurchaseTokenIndex: (index: number) => void;
}

function Arts(props: ArtsProps) {
  const classes = useStyles();

  return (
    <Container className={classes.card}>
      <Grid container alignItems="stretch" spacing={3}>
        {props.cardInfo.length === 0 ? (
          <CircularProgress size={80} style={{ margin: "0 auto" }} />
        ) : (
          <>
            {props.cardInfo.map((info, index) => (
              <Grid key={index} item xs={12} sm={4} md={4}>
                <AppCard
                  id={info.id}
                  index={info.index}
                  price={info.price}
                  remainingAmount={info.remainingAmount}
                  setPurchaseTokenIndex={props.setPurchaseTokenIndex}
                />
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </Container>
  );
}

export default Arts;
