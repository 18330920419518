import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./components/style/theme";
import { ChainId, Config, DAppProvider } from "@usedapp/core";
import { NftProvider } from "use-nft";
import { getDefaultProvider } from "ethers";

export const maticEndpoint = "https://polygon-rpc.com/";

export const mumbaiEndpoint = "https://rpc-mumbai.maticvigil.com";

export const config: Config = {
  readOnlyChainId:
    process.env.REACT_APP_CHAIN_ID === "137" ? ChainId.Polygon : ChainId.Mumbai,
  readOnlyUrls: {
    [ChainId.Polygon]: maticEndpoint,
    [ChainId.Mumbai]: mumbaiEndpoint,
  },
};

export const ethersConfig = {
  provider: getDefaultProvider(
    process.env.REACT_APP_CHAIN_ID === "137" ? maticEndpoint : mumbaiEndpoint
  ),
};

ReactDOM.render(
  <NftProvider fetcher={["ethers", ethersConfig]}>
    <DAppProvider config={config}>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </StylesProvider>
    </DAppProvider>
  </NftProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
