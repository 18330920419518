import { createTheme } from "@material-ui/core/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#F77BA0",
      contrastText: "#fff",
    },
    secondary: {
      main: "#53BCB5",
      contrastText: "#000",
    },
  },
  typography: {
    fontFamily: [
      "Quicksand",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h5: {
      fontWeight: 600,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontWeight: "bold",
      },
    },
  },
});
