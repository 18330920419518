import React from "react";
import "./App.css";
import PurchasePage from "./components/pages/PurchasePage";

function App() {
  return (
    <div className="App">
      <PurchasePage />
    </div>
  );
}

export default App;
