import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Link,
  Typography,
} from "@material-ui/core";
import { formatEther } from "ethers/lib/utils";
import React, { useEffect, useMemo, useState } from "react";
import { AppCardInfo } from "./AppCard";
import { useNft } from "use-nft";
import { BigNumber } from "ethers";
import { useIPFSImageURL } from "../../hooks/useIPFSImageURL";
import { WalletButton } from "./WalletConnect";

export interface PurchaseDialogProps {
  account: string | undefined | null;
  buy: (...args: any[]) => void;
  cardInfo: AppCardInfo[];
  purchaseTokenIndex: number | undefined;
  setPurchaseTokenIndex: (index: number | undefined) => void;
  walletBalance: BigNumber | undefined;
}

const PurchaseDialog: React.FC<PurchaseDialogProps> = (props) => {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    setOpen(props.purchaseTokenIndex !== undefined);
  }, [props.purchaseTokenIndex]);

  const tokenInfo = useMemo(() => {
    if (
      props.cardInfo === undefined ||
      props.cardInfo.length === 0 ||
      props.purchaseTokenIndex === undefined
    ) {
      return undefined;
    }
    return props.cardInfo[props.purchaseTokenIndex];
  }, [props.cardInfo, props.purchaseTokenIndex]);

  const { nft } = useNft(
    process.env?.REACT_APP_NFT_ADDRESS ?? "",
    tokenInfo?.id.toString() ?? ""
  );

  const imageURL = useIPFSImageURL(nft);

  const isInsufficient = useMemo(() => {
    if (props.walletBalance === undefined) {
      return false;
    }
    if (tokenInfo?.price === undefined) {
      return false;
    }
    return props.walletBalance.sub(tokenInfo?.price).isNegative();
  }, [props.walletBalance, tokenInfo?.price]);

  if (props.purchaseTokenIndex === undefined) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        props.setPurchaseTokenIndex(undefined);
        setOpen(false);
      }}
      fullWidth
      maxWidth="xs"
    >
      <DialogContent style={{ textAlign: "center" }}>
        <img
          src={imageURL}
          alt="NFT image"
          style={{ width: "100%", height: "100%" }}
        />
        <Box>
          {tokenInfo?.price !== undefined && (
            <Typography
              variant="h5"
              color="primary"
              component="p"
              style={{ fontSize: 30 }}
            >
              {formatEther(tokenInfo?.price.mul(1).toString())}
              <Typography
                component="span"
                color="textSecondary"
                style={{ paddingLeft: 8 }}
              >
                MATIC
              </Typography>
            </Typography>
          )}
          <Typography
            variant="caption"
            style={{ display: "block", textAlign: "center" }}
          >
            {tokenInfo?.remainingAmount.toString()} /{" "}
            {tokenInfo?.saleSupply.toString()}
          </Typography>
        </Box>
        <Box>
          <div
            style={{
              textAlign: "left",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Checkbox
              color="primary"
              checked={checked}
              onChange={() => setChecked(!checked)}
            />
            <span>
              I agree with the
              <Link
                href="https://pprct.net/terms/"
                target="_blank"
                rel="noopener"
                style={{
                  paddingLeft: 8,
                }}
              >
                Terms & Conditions
              </Link>
            </span>
          </div>
        </Box>
        <Box>
          {isInsufficient && (
            <Typography color="error">Insufficient wallet balance</Typography>
          )}
          {!props.account && (
            <Box p={1}>
              <WalletButton account={props.account} />
            </Box>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              props.buy(tokenInfo?.id, 1, { value: tokenInfo?.price });
            }}
            disabled={
              props.walletBalance === undefined ||
              tokenInfo?.price === undefined ||
              tokenInfo?.remainingAmount.eq(0) ||
              !checked ||
              isInsufficient
            }
            disableElevation
            fullWidth
            style={{ marginBottom: 8 }}
          >
            Purchase
          </Button>
        </Box>
        <Box>
          <Button
            variant="outlined"
            onClick={() => {
              props.setPurchaseTokenIndex(undefined);
              setOpen(false);
            }}
            disableElevation
            fullWidth
          >
            Cancel
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PurchaseDialog;
