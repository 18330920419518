import React from "react";
import { Box, Typography, Dialog, DialogTitle } from "@material-ui/core";
import { ChainId } from "@usedapp/core";

export interface ChainDialogProps {
  readonly chainId: ChainId | undefined;
}

const ChainDialog: React.FC<ChainDialogProps> = ({ chainId }) => {
  const chainName =
    process.env.REACT_APP_CHAIN_ID === "137" ? "Polygon" : "Mumbai";

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={
        chainId !== undefined &&
        chainId.toString() !== process.env.REACT_APP_CHAIN_ID
      }
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle
        id="simple-dialog-title"
        style={{ textAlign: "center", color: "#F77BA0" }}
      >
        Switch to a supported Ethereum network
      </DialogTitle>
      <Box m={2} textAlign="center">
        <Typography>
          To purchase NFT, please switch to the {chainName} network.
        </Typography>
      </Box>
    </Dialog>
  );
};

export default ChainDialog;
