import { Falsy } from "@usedapp/core/dist/esm/src/model/types";
import { useContractCall } from "@usedapp/core";
import { BigNumber, ethers } from "ethers";
import { pipryctoABI } from "../constants";

export function useTokenIdList(tokenAddress: string | Falsy): BigNumber[] {
  const [tokenIdList] = useContractCall(
    tokenAddress && {
      abi: new ethers.utils.Interface(pipryctoABI),
      address: tokenAddress,
      method: "tokenIdList",
      args: [],
    }
  ) ?? [[]];

  return tokenIdList
    .map((tokenId: BigNumber) => tokenId.toNumber())
    .reverse()
    .map((tokenId: number) => BigNumber.from(tokenId));
}
