import { BigNumber, ethers } from "ethers";
import { useContractCall } from "@usedapp/core";
import { pipryctoABI } from "../constants";

export function useRemainingAmountsOf(
  tokenAddress: string,
  tokenIdList: BigNumber[]
) {
  const [remainingAmounts] =
    useContractCall(
      tokenAddress && {
        abi: new ethers.utils.Interface(pipryctoABI),
        address: tokenAddress,
        method: "remainingAmountsOf",
        args: [tokenIdList],
      }
    ) ?? [];
  return remainingAmounts;
}
