import * as React from "react";
import { Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { useMemo } from "react";

type Market = "opensea" | "rarible";

export interface MarketplaceLinkProps {
  readonly chainId: string;
  readonly market: Market;
  readonly address: string;
  readonly tokenId?: string;
  readonly small?: boolean;
}

const baseURLs: { [chainId: string]: string } = {
  "137": "https://opensea.io/",
  "80001": "https://testnets.opensea.io/",
};

const MarketplaceLink: React.FC<MarketplaceLinkProps> = ({
  chainId,
  market,
  address,
  tokenId,
  small = false,
}) => {
  const baseUrl: string | undefined = baseURLs?.[chainId];
  const name = market === "opensea" ? "OpenSea" : "Rarible";
  const network = chainId === "137" ? "matic" : "mumbai";
  const link = useMemo(() => {
    if (market === "opensea" && tokenId === undefined) {
      return `${baseUrl}${address}`;
    }
    if (market === "opensea") {
      return `${baseUrl}assets/${network}/${address}/${tokenId}`;
    }
    if (tokenId === undefined) {
      return `${baseUrl}${address}`;
    }
    return `${baseUrl}token/${address}:${tokenId}`;
  }, [market, baseUrl, address, tokenId]);

  if (baseUrl === undefined) {
    return null;
  }

  const handleClick = () => {
    window.open(link, "_blank");
  };
  return (
    <>
      <Button
        color="secondary"
        variant="outlined"
        fullWidth
        onClick={handleClick}
      >
        {name}&nbsp;&nbsp;
        <FontAwesomeIcon
          icon={faExternalLinkAlt}
          size={small ? "sm" : undefined}
        />
      </Button>
    </>
  );
};

export default MarketplaceLink;
