import { useMemo } from "react";
import { NftMetadata } from "use-nft";

export function useIPFSImageURL(nft: NftMetadata | undefined) {
  const imageURL = useMemo(() => {
    if (nft?.image === undefined) {
      return "";
    }
    if (!nft.image.startsWith("ipfs://")) {
      return nft.image;
    }
    return `https://ipfs.io/ipfs/${nft.image.split("ipfs://")[1]}`;
  }, [nft]);

  return imageURL;
}
