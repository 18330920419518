import * as React from "react";
import PurchasePageTemplate from "../templates/PurchasePageTemplate";
import { BigNumber, ethers } from "ethers";
import {
  TransactionStatus,
  useContractFunction,
  useEtherBalance,
  useEthers,
} from "@usedapp/core";
import { useTokenIdList } from "../../hooks/useTokenIdList";
import { useEffect, useMemo, useState } from "react";
import { AppCardInfo } from "../molecules/AppCard";
import { pipryctoABI } from "../../constants";
import { usePricesOf } from "../../hooks/usePricesOf";
import { useRemainingAmountsOf } from "../../hooks/useRemainingAmountsOf";
import { useTotalSuppliesOf } from "../../hooks/useTotalSuppliesOf";

const pipryctoAddress = process.env.REACT_APP_NFT_ADDRESS ?? "";

function PurchasePage() {
  const { account, chainId } = useEthers();
  const walletBalance = useEtherBalance(account);
  const tokenIdList = useTokenIdList(pipryctoAddress);
  const prices = usePricesOf(pipryctoAddress, tokenIdList ?? []);
  const remainingAmounts = useRemainingAmountsOf(
    pipryctoAddress,
    tokenIdList ?? []
  );
  const totalSupplies = useTotalSuppliesOf(pipryctoAddress, tokenIdList ?? []);
  const [purchaseTokenIndex, setPurchaseTokenIndex] = useState<
    number | undefined
  >(undefined);

  const contract = new ethers.Contract(pipryctoAddress, pipryctoABI);
  const { send: buy, state: buyState } = useContractFunction(contract, "buy");
  const [state, setState] = useState<TransactionStatus>(buyState);

  useEffect(() => {
    if (purchaseTokenIndex === undefined) {
      setState({ status: "None" });
      return;
    }
  }, [purchaseTokenIndex]);

  useEffect(() => {
    if (buyState.status !== "None") {
      setState(buyState);
      return;
    }
  }, [buyState.status]);

  const nftInfoList: AppCardInfo[] = useMemo(() => {
    const lengthSet = new Set([
      tokenIdList?.length ?? 0,
      prices?.length ?? 0,
      remainingAmounts?.length ?? 0,
      totalSupplies?.length ?? 0,
    ]);

    if (lengthSet.size !== 1) {
      return [];
    }

    return (
      tokenIdList?.map((id: BigNumber, index: number) => {
        return {
          id,
          index,
          price: prices[index],
          remainingAmount: remainingAmounts[index],
          saleSupply: totalSupplies[index],
        };
      }) ?? []
    );
  }, [tokenIdList, prices, remainingAmounts, totalSupplies]);

  return (
    <PurchasePageTemplate
      account={account}
      cardInfo={nftInfoList}
      chainId={chainId}
      buy={buy}
      buyState={state}
      setPurchaseTokenIndex={setPurchaseTokenIndex}
      purchaseTokenIndex={purchaseTokenIndex}
      walletBalance={walletBalance}
    />
  );
}

export default PurchasePage;
