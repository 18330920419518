import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { BigNumber, ethers } from "ethers";
import { useNft } from "use-nft";
import { useIPFSImageURL } from "../../hooks/useIPFSImageURL";

const useStyles = makeStyles({
  root: {},
  media: {
    width: "100%",
    paddingTop: "100%",
  },
  font: {
    font: "Roboto",
  },
  color: {
    color: "#F77BA0",
  },
});

export interface AppCardInfo {
  readonly id: BigNumber;
  readonly index: number;
  readonly price: BigNumber;
  readonly remainingAmount: BigNumber;
  readonly saleSupply: BigNumber;
}

export interface AppCardProps {
  readonly id: BigNumber;
  readonly index: number;
  readonly price: BigNumber;
  readonly remainingAmount: BigNumber;
  readonly setPurchaseTokenIndex: (index: number) => void;
}

export default function AppCard(props: AppCardProps) {
  const classes = useStyles();

  const { nft } = useNft(
    process.env?.REACT_APP_NFT_ADDRESS ?? "",
    props.id.toString()
  );

  const imageURL = useIPFSImageURL(nft);

  return (
    <Card className={classes.root} variant="outlined">
      <CardMedia
        className={classes.media}
        image={imageURL}
        title={nft?.name ?? ""}
      />
      <CardContent>
        <Typography
          gutterBottom
          className={classes.font}
          variant="h5"
          component="h2"
          align="left"
          color="primary"
        >
          {nft?.name ?? ""}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {nft?.description ?? ""}
        </Typography>
        <Typography variant="h5" color="primary" component="p" align="right">
          {ethers.utils.formatEther(props.price ?? "0")}
          <Typography
            component="span"
            color="textSecondary"
            style={{ paddingLeft: 8 }}
          >
            MATIC
          </Typography>
        </Typography>
      </CardContent>
      <CardActions style={{ justifyContent: "center" }}>
        <Button
          size="large"
          type="submit"
          variant="contained"
          color="primary"
          fullWidth={true}
          disableElevation
          disabled={props.remainingAmount.isZero()}
          onClick={() => props.setPurchaseTokenIndex(props.index)}
        >
          {props.remainingAmount.isZero() ? "Sold out" : "Purchase"}
        </Button>
      </CardActions>
    </Card>
  );
}
